
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from "react";
import Script from "next/script";
import "styles/globals.css";

const __Page_Next_Translate__ = function App({ Component, pageProps }) {
  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
            lazyOnload
          />
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){ dataLayer.push(arguments); }
                  gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                  gtag('js', new Date());
                  gtag("consent", "default", {
                    ad_storage: "denied",
                    analytics_storage: "denied"
                  });
                `,
            }}
            lazyOnload
          />
        </>
      )}
      <Component {...pageProps} />
    </>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  